<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" v-if="show_block.includes(key) && item.enable" :id="key" :key="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->  

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!--Scope start--> 
                    <div class="content-onglets table" v-else-if="key == 'detail_indication_under_review_original' && item.show">

                        <table class="comparateur2" v-if="data.tlv['indication_under_review']">
                            <thead>
                                <tr>
                                    <th width="100%"> Indication under review </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left textJustify">
                                        <span v-html="data.tlv['indication_under_review']"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="comparateur2  text-center border-top-gray" v-if="data.tlv['same_ma'] && data.tlv['same_ma'] != 0">
                            <tbody>
                                <tr>
                                    <th style="width: 50%">
                                        {{$t('Same as MA')}}
                                    </th>
                                    <td>                                
                                        {{$t('yes_no_' + data.tlv['same_ma'])}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="comparateur2 " v-if="data.tlv['scope_approved_indication'] && data.tlv['same_ma'] == '1'">
                            <thead>
                                <tr>
                                    <th width="100%"> {{$t('scope_approved_indication')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left textJustify">
                                        <span v-html="data.tlv['scope_approved_indication']"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="comparateur2 " v-if="data.tlv['scope_reimbursement_scope_indication']">
                            <thead>
                                <tr>
                                    <th width="100%"> {{$t('scope_reimbursement_scope_indication')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left textJustify">
                                        <span v-html="data.tlv['scope_reimbursement_scope_indication']"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Scope end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!-- detail_marketing_authorization_date start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_marketing_authorization_date' && item.show">
                        <table class="comparateur2 border-top-gray" >
                            <tbody>
                                <tr v-if="data.tlv['decision_approval_restriction_select'] == 2 && data.tlv['decision_approval_restrictions']">
                                    <td width="40%" style="vertical-align: top"><strong>Approval restrictions</strong></td>
                                    <td class="text-left textJustify">
                                        {{$t('yes_no_' + data.tlv['decision_approval_restriction_select'])}}
                                        <span v-html="data.tlv['decision_approval_restrictions']"></span>
                                    </td>
                                </tr>

                                <tr v-if="data.tlv['decision_restrication'] == 2 && data.tlv['decision_approval_restriction_select'] == 2">
                                    <td width="40%" style="vertical-align: top"><strong> {{$t('decision_restrication')}} </strong></td>
                                    <td class="text-left textJustify">
                                        {{$t('yes_no_' + data.tlv['decision_restrication'])}}
                                    </td>
                                </tr>

                                <tr v-if="data.tlv['decision_conditions_approval'] && data.tlv['decision_conditions_approval_select'] != 0">
                                    <td width="40%" style="vertical-align: top"><strong> {{$t('decision_conditions_approval')}} </strong></td>
                                    <td class="text-left textJustify">
                                        {{$t('yes_no_' + data.tlv['decision_conditions_approval_select'])}} 
                                        <template v-if="data.tlv['decision_conditions_approval_select'] == 2 && data.tlv['decision_conditions_approval']">
                                            <span v-html="data.tlv['decision_conditions_approval']"></span>
                                        </template>
                                    </td>
                                </tr>

                                <tr style="vertical-align: top" v-if="data.tlv['marketing_authorization_date']">
                                    <td width="40%"><strong>Marketing authorization date (National, decentralized or mutual)</strong></td>
                                    <td class="text-left textJustify"> {{data.tlv['marketing_authorization_date'] ? DDMMMYY(data.tlv['marketing_authorization_date']) : '-'}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- detail_marketing_authorization_date start -->

                    <!--MA spec start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_MaSpac' && item.show">
                        <table class="comparateur2  text-left border-top-gray">
                            <tr colspan="3">
                                <td class="bold"><strong> {{$t('TRS_AMM')}} </strong></td>
                                <td> {{data.tlv['tlv_type_amm'] ? $t('ceesp_type_amm_' + data.tlv['tlv_type_amm']): "-" }}</td>
                            </tr>
                        </table>
                    </div>
                    <!--MA spec end-->

                    <!-- key document start -->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- key document end -->

                    <!--3Clinical rationale start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_clinical_rationale_safety_concerns' && item.show">
                        <table class="comparateur2  text-left tb-border border-top-gray" v-for="(value_TlvClinicalRationale, key_TlvClinicalRationale) in data.tlv['tlv_clinical_rationale']" :key="'TlvClinicalRationale_'+key_TlvClinicalRationale"> 
                            <tbody>
                                <tr>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" width="40%"></td>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" width="30%"></td>
                                    <th> {{$t('clinical_rationale_other')}} </th>
                                </tr>

                                <template v-if="value_TlvClinicalRationale['TlvComparator']">
                                    <tr v-for="(value_comname, key_comname) in value_TlvClinicalRationale['TlvComparator']" :key="'TlvComparator' + key_comname">
                                        <th style="text-align: left;" width="40%"><strong> {{$t('Comparator')}} </strong></th>
                                        <td class="textJustify"> {{value_comname['comparator'] ? value_comname['comparator'] : '-' }}</td>
                                        <td> {{value_comname['accepted'] != 1 ? 'Accepted by TLV' : '-'}} </td>
                                    </tr>
                                </template>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_safety_concerns'] != 0 || value_TlvClinicalRationale['clinical_rationale_safety_concerns_text']">
                                    <th style="text-align: left;" width="40%"><strong> {{$t('clinical_rationale_safety_concerns')}} </strong></th>
                                    <td class="textJustify"> {{$t('yes_no_' + value_TlvClinicalRationale['clinical_rationale_safety_concerns'])}} </td>
                                    <td> {{value_TlvClinicalRationale['clinical_rationale_safety_concerns_text'] ? value_TlvClinicalRationale['clinical_rationale_safety_concerns_text'] : '-'}} </td>
                                </tr>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_same_safety'] || value_TlvClinicalRationale['clinical_rationale_same_safety_text']">
                                    <th style="text-align: left;"  width="40%"><strong> {{$t('clinical_rationale_same_safety')}} </strong></th>
                                    <td class="textJustify"> {{value_TlvClinicalRationale['clinical_rationale_same_safety'] ? $t(value_TlvClinicalRationale['clinical_rationale_same_safety']) : '-'}} </td>
                                    <td> {{value_TlvClinicalRationale['clinical_rationale_same_safety_text'] ? value_TlvClinicalRationale['clinical_rationale_same_safety_text'] : '-'}} </td>
                                </tr>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_alternative_to_comparator_needed'] && value_TlvClinicalRationale['clinical_rationale_alternative_to_comparator_needed'] != 0  || value_TlvClinicalRationale['clinical_rationale_alternative_to_comparator_needed_text']">
                                    <th style="text-align: left;"  width="40%"><strong>Alternative to comparator needed</strong></th>
                                    <td class="textJustify"> {{ $t('yes_no_' + value_TlvClinicalRationale['clinical_rationale_alternative_to_comparator_needed'])}} </td>
                                    <td> {{ value_TlvClinicalRationale['clinical_rationale_alternative_to_comparator_needed_text'] ? value_TlvClinicalRationale['clinical_rationale_alternative_to_comparator_needed_text'] : '-' }}</td>
                                </tr>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_same_safety'] && value_TlvClinicalRationale['clinical_rationale_variety'] != 0 || value_TlvClinicalRationale['clinical_rationale_variety_text']">
                                    <th style="text-align: left;"  width="40%"><strong> {{$t('clinical_rationale_variety')}} </strong></th>
                                    <td class="textJustify"> {{$t('yes_no_'+value_TlvClinicalRationale['clinical_rationale_variety'])}} </td>
                                    <td> {{value_TlvClinicalRationale['clinical_rationale_variety_text'] ? value_TlvClinicalRationale['clinical_rationale_variety_text'] : '-'}}</td>
                                </tr>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_same_effect'] || value_TlvClinicalRationale['clinical_rationale_same_effect_text']">
                                    <th style="text-align: left;"  width="40%"><strong> {{$t('clinical_rationale_same_effect')}} </strong></th>
                                    <td class="textJustify"> {{value_TlvClinicalRationale['clinical_rationale_same_effect'] ? $t(value_TlvClinicalRationale['clinical_rationale_same_effect']) : '-'}} </td>
                                    <td> {{value_TlvClinicalRationale['clinical_rationale_same_effect_text'] ? value_TlvClinicalRationale['clinical_rationale_same_effect_text'] : '-'}} </td>
                                </tr>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_same_response_rate'] || value_TlvClinicalRationale['clinical_rationale_same_response_rate_text']">
                                    <th style="text-align: left;"  width="40%"><strong> {{$t('clinical_rationale_same_response_rate')}} </strong></th>
                                    <td class="textJustify"> {{value_TlvClinicalRationale['clinical_rationale_same_response_rate'] ? $t(value_TlvClinicalRationale['clinical_rationale_same_response_rate']) : '-'}} </td>
                                    <td> {{value_TlvClinicalRationale['clinical_rationale_same_response_rate_text'] ? value_TlvClinicalRationale['clinical_rationale_same_response_rate_text'] : '-'}} </td>
                                </tr>

                                <tr v-if="value_TlvClinicalRationale['clinical_rationale_other']">
                                    <th style="text-align: left;"  width="40%"><strong> {{$t('clinical_rationale_other')}} </strong></th>
                                    <td colspan="2" class="textJustify"> <span v-html="value_TlvClinicalRationale['clinical_rationale_other']"></span> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Clinical rationale end-->

                    <!--Economic rationale start--> 
                    <div class="content-onglets table" v-else-if="key == 'detail_economic_rationale_same_price' && item.show">
                        <table class="comparateur2  text-left border-top-gray" v-for="(value_TlvEconomicRationale, key_TlvEconomicRationale) in data.tlv['tlv_economic_rationale']" :key="'TlvEconomicRationale_'+key_TlvEconomicRationale">
                            <tbody>                
                                <tr>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" width="40%"></td>
                                    <td style="border-right: 0; border-left: 0;border-top: 0;" width="30%"></td>
                                    <th> {{$t('clinical_rationale_other')}} </th>
                                </tr>

                                <tr v-if="value_TlvEconomicRationale['economic_rationale_same_price']">
                                    <th style="text-align: left;" width="40%"><strong> {{$t('economic_rationale_same_price')}} </strong></th>
                                    <td class="textJustify"> {{$t(value_TlvEconomicRationale['economic_rationale_same_price'])}} </td>
                                    <td> {{value_TlvEconomicRationale['economic_rationale_same_price_text'] ? value_TlvEconomicRationale['economic_rationale_same_price_text'] : '-'}} </td>
                                </tr>
                                <tr v-if="value_TlvEconomicRationale['economic_rationale_considered'] && value_TlvEconomicRationale['economic_rationale_considered'] != 0">
                                    <th style="text-align: left;" width="40%"><strong> {{$t('economic_rationale_considered')}} </strong></th>
                                    <td class="textJustify"> {{$t('yes_no_' + value_TlvEconomicRationale['economic_rationale_considered'])}} </td>
                                    <td> {{value_TlvEconomicRationale['economic_rationale_considered_text'] ? value_TlvEconomicRationale['economic_rationale_considered_text'] : '-'}} </td>
                                </tr>
                                <tr v-if="value_TlvEconomicRationale['economic_rationale_addtional'] && value_TlvEconomicRationale['economic_rationale_addtional'] != 0">
                                    <th style="text-align: left;" width="40%"><strong>Additional value related to administration</strong></th>
                                    <td class="textJustify"> {{$t('yes_no_' + value_TlvEconomicRationale['economic_rationale_addtional'])}} </td>
                                    <td> {{value_TlvEconomicRationale['economic_rationale_addtional_text'] ? value_TlvEconomicRationale['economic_rationale_addtional_text'] : '-'}} </td>
                                </tr>
                                <tr v-if="value_TlvEconomicRationale['economic_rationale_other']">
                                    <th style="text-align: left;" width="40%"><strong> {{$t('economic_rationale_other')}} </strong></th>
                                    <td colspan="2" class="textJustify"> <span v-html="value_TlvEconomicRationale['economic_rationale_other']"></span> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Economic rationale end-->

                    <!--Details of Economic Analysis start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_TlvEffectivenessEvidence' && item.show">
                        <table class="comparateur2  text-center">
                            <template v-for="(value, key2) in data.tlv['tlv_effectiveness_evidence']" :key="'TlvEffectivenessEvidence_tr1_'+key2">
                                <tr class="noborder" ><td colspan="6" style="border: 0px;"></td></tr>
                                <tr>
                                    <th width="17%" > {{$t('details_economic_analysis_type')}} </th>
                                    <th width="17%">Index treatment</th>
                                    <th width="17%"> {{$t('details_economic_analysis_icer_source')}} </th>
                                    <th width="17%"> {{$t('details_economic_analysis_icer_comparator')}} </th>
                                    <th width="17%"> {{$t('details_economic_analysis_model_type')}} </th>
                                    <th width="17%"> {{$t('details_economic_analysis_icer_basecase')}} </th>
                                </tr>
                                <tr>
                                    <td>
                                        {{value['icer_type'] ? value['icer_type'] : '-'}}
                                    </td>
                                    <td>
                                        {{value['index_treatment'] ? value['index_treatment'] : '-'}}
                                    </td>
                                    <td>
                                        {{value['icer_source'] ? value['icer_source'] : '-' }} 
                                    </td>
                                    <td>
                                        {{value['icer_comparator'] ? value['icer_comparator'] : '-' }}
                                    </td>
                                    <td>
                                        {{value['icer_model_type'] ? value['icer_model_type'] : '-' }}
                                    </td>
                                    <td>
                                        {{value['icer_basecase_text'] ? value['icer_basecase_text'] : '-'}}
                                    </td>
                                </tr>
                                <template v-if="value['hta_agency_comment_on_icer']">
                                    <tr :key="'TlvEffectivenessEvidence_tr4_'+key2">
                                        <th colspan="6">HTA agency comment on ICER</th>
                                    </tr>
                                    <tr :key="'TlvEffectivenessEvidence_tr5_'+key2">
                                        <td colspan="6">
                                             {{value['hta_agency_comment_on_icer']}}
                                        </td>
                                    </tr>
                                </template>
                            </template> 
                        </table>
                    </div>
                    <!--Details of Economic Analysis end-->

                    <!--Conclusions on evidence start--> 
                    <div class="content-onglets table" v-else-if="key == 'detail_conclusions_on_evidence' && item.show">
                        <table class="comparateur2" v-if="data.tlv['conclusions_hta_agency_clinical']">
                            <thead>
                                <tr>
                                    <th width="100%"  > {{$t('conclusions_hta_agency_clinical')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left textJustify">
                                        <span v-html="data.tlv['conclusions_hta_agency_clinical']"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="comparateur2" v-if="data.tlv['conclusions_hta_agency_economic']">
                            <thead>
                                <tr>
                                    <th width="100%" > {{$t('conclusions_hta_agency_economic')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left textJustify">
                                        <span v-html="data.tlv['conclusions_hta_agency_economic']"></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Conclusions on evidence end-->

                    <!-- Budget impact start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_budget_impact' && item.show">
                        <table class="comparateur2 ">
                            <tr><th class="text-center">Number of eligible patients</th><td class="text-left"> {{data.tlv['number_of_eligible_patients'] ? data.tlv['number_of_eligible_patients'] : '-'}} </td></tr>
                            <tr><th class="text-center">Budget impact</th><td class="text-left"> {{data.tlv['budget_impact'] ? data.tlv['budget_impact'] : '-'}} </td></tr>
                        </table>
                    </div>
                    <!-- Budget impact end -->

                     <!--Other/Comment start--> 
                    <div class="content-onglets" v-else-if="key == 'detail_other_comment' && item.show">
                        <p v-html="data.tlv['other_comment']"></p>
                    </div>
                    <!--Other/Comment end-->

                    <!--References start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_references_ema' && item.show">
                        <table class="comparateur2  text-left">
                            <tbody class="noLineLR">               
                                <tr v-if="data.tlv['references_county_council']">
                                    <td style="vertical-align: middle;" width="40%"><strong> {{$t('references_county_council')}} </strong></td>
                                    <td style="vertical-align: middle;" class="textJustify"> {{$t('yes_1')}} </td>
                                    <td class="textJustify"> {{data.tlv['references_county_council_text']}} </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="comparateur2 " v-if="data.tlv['tlv_references_clinical_trial'].length > 0">
                            <thead>
                                <tr>
                                    <th width="100%"> {{$t('clinical_trials')}} </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, key) in data.tlv['tlv_references_clinical_trial']" :key="'TlvReferencesClinicalTrial_'+key">
                                    <td class="textJustify">
                                        {{value['text']}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>  
                    </div>
                    <!--References end-->

                    <!--EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EssaisClinique end-->   

                    <!-- detail_EvaluationEconomic  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.tlv.evaluation_economic" />

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.tlv['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->

                    <!-- detail_TlvCostTreatment start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_TlvCostTreatment' && item.show">
                        <table class="comparateur2  text-center">
                            <thead>
                                <tr>                                          
                                    <th width="60%"> {{$t('Cost of treatment')}} </th>                       
                                    <th width="40%"> {{$t('Vs comparator')}} </th>                       
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, key) in data.tlv['tlv_cost_treatment']" :key="'TlvCostTreatment_'+key">
                                    <td> {{ value['cost_of_treatment'] ? value['cost_of_treatment'] : '-' }} {{ value['gross'] ? " - Gross" : "" }} {{ value['net'] ? " - Net" : "" }} </td>
                                    <td> {{value['comparator'] ? value['comparator'] : '-'}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- detail_TlvCostTreatment end -->

                    <!-- Section Detail End -->
                    

                </div>
            </template>
            
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
 
         </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import EvaluationEconomic from '../evaluation_economic.vue';
import BudgetImpact from '../budget_impact.vue';
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'tlv',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        Conclusion,
        LinkAgency,
        EssaisClinique,
        EvaluationEconomic,
        BudgetImpact,
        KeyDocument,
        InformationGeneral
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_indication_under_review_original : {'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false },                
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_marketing_authorization_date : {'title' : 'General information', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_MaSpac : {'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_clinical_rationale_safety_concerns : {'title' : 'clinical_rationale', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_economic_rationale_same_price : {'title' : 'economic_rationale', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_TlvEffectivenessEvidence : {'title' : 'details_of_economic_analysis', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_conclusions_on_evidence : {'title' : 'conclusions_on_evidence', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_budget_impact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_other_comment : {'title' : 'other_comment', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_references_ema : {'title' : 'references', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true },                
                detail_TlvCostTreatment : {'title' : 'Cost_of_treatment', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },                
            },
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.tlv){                 
            
            if(this.data['ma_date'])
            { 
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.tlv['indication_under_review'] || this.data.tlv['scope_approved_indication'] || this.data.tlv['scope_reimbursement_scope_indication'])
            { 
                this.list_check_box['detail_indication_under_review_original'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  
            { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.tlv['decision_restrication'] == 2 && this.data.tlv['decision_approval_restriction_select'] == 2 || 
                this.data.tlv['decision_conditions_approval'] && this.data.tlv['decision_conditions_approval_select'] && this.data.tlv['decision_conditions_approval_select'] != 0 ||
                this.data.tlv['marketing_authorization_date'])
            { 
                this.list_check_box['detail_marketing_authorization_date'].enable = true
            }
            if(this.data.tlv['tlv_type_amm'])
            { 
                this.list_check_box['detail_MaSpac'].enable = true
            } 
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0)
            { 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if(this.data.tlv['tlv_clinical_rationale'].length > 0)
            { 
                this.list_check_box['detail_clinical_rationale_safety_concerns'].enable = true
            }
            if(this.data.tlv['tlv_economic_rationale'].length > 0)
            { 
                this.list_check_box['detail_economic_rationale_same_price'].enable = true
            }
            if(this.data.tlv['tlv_effectiveness_evidence'].length > 0)
            { 
                this.list_check_box['detail_TlvEffectivenessEvidence'].enable = true
            }
            if(this.data.tlv['conclusions_hta_agency_clinical'] || this.data.tlv['conclusions_hta_agency_economic'])
            { 
                this.list_check_box['detail_conclusions_on_evidence'].enable = true
            }
            if(this.data.tlv['number_of_eligible_patients'] || this.data.tlv['budget_impact'])
            { 
                this.list_check_box['detail_budget_impact'].enable = true
            }
            if(this.data.tlv['other_comment'])
            { 
                this.list_check_box['detail_other_comment'].enable = true
            }
            if(this.data.tlv['references_county_council'] || this.data.tlv['tlv_references_clinical_trial'].length > 0)
            { 
                this.list_check_box['detail_references_ema'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            } 
            if(this.data.tlv['evaluation_economic'].length > 0)
            { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            } 
            if(this.data.tlv['budget_impacts'].length > 0)
            { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            if(this.data.tlv['tlv_cost_treatment'].length > 0)
            { 
                let tlv_cost_treatment = []
                for(let key in this.data.tlv['tlv_cost_treatment']){
                    if(this.data.tlv['tlv_cost_treatment'][key]['cost_of_treatment'] ||
                        this.data.tlv['tlv_cost_treatment'][key]['comparator'] ||
                        this.data.tlv['tlv_cost_treatment'][key]['gross'] != 0 ||
                        this.data.tlv['tlv_cost_treatment'][key]['net'] != 0)
                    {
                        tlv_cost_treatment.push(this.data.tlv['tlv_cost_treatment'][key])
                    }
                }
                if(tlv_cost_treatment.length > 0){
                    this.list_check_box['detail_TlvCostTreatment'].enable = true
                }
            }
            
        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }        
    },
}

</script>

<style scoped>
a {
    cursor: pointer;
}

.fa-angle-up {
    color : #ff6300;
}
section {
    margin-top : 10px;
    margin-bottom : 10px;
}
</style>